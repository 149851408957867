import React, {useState, useEffect} from 'react';
import {motion} from 'framer-motion';
import {ServicesVideoBanner} from '../components/common/VideoComponent';
import ServicesContent from '../components/services-components/ServicesContent';
import education from '../components/videos/services-education.mp4';
import sports from '../components/videos/services-sports.mp4';
import workshops from '../components/videos/services-workshop.mp4';
import community from '../components/videos/services-community.mp4';

import '../components/css/Services.css';
import {Button} from '@material-ui/core';
import {Link} from 'react-router-dom';

const servicesData = [
  {
    bgColor: '#ff6a6a',
    title: 'Education',
    videoSrc: education,
    description: `Step into a world of boundless opportunity and join us on a journey of discovery at Unity Horizons. Explore our educational programs, where every lesson is a stepping stone towards empowerment and growth.
    `,
    longerDescription: `Whether you're a lifelong learner, a career enthusiast, or simply curious about what the future holds, our doors are open to you. Discover a community of learners, mentors, and entrepreneurs ready to support you on your educational journey.
    `,
    link: '/services/education',
  },
  {
    bgColor: '#2e466f',
    title: 'Health and Wellbeing',
    videoSrc: sports,
    description: `In the realm of health and wellbeing at Unity Horizons, every step you take is a stride towards a healthier, happier you. Join us on a journey of self-discovery and wellness, where we invite you to explore our range of programs designed to nurture your body, mind, and spirit.
    `,
    longerDescription: `Whether you're looking to improve your physical fitness, prioritise your mental health, or simply find balance in your busy life, our doors are open to you. Discover a community of support, guidance, and encouragement as you embark on your journey towards greater health and wellbeing.
    `,
    link: '/services/sports',
  },
  {
    bgColor: '#ec915c',
    title: 'Workshops',
    videoSrc: workshops,
    description: `Embark on an adventure of learning and inspiration at Unity Horizons, where our workshops are transformative experiences, not just gatherings. They’re opportunities to ignite your passions and unleash your potential. Join us as we explore new ideas, share experiences, and learn from one another. 
    `,
    longerDescription: `Whether you're interested in personal development, professional growth, or simply seeking to expand your horizons, our workshops offer something for everyone. Discover a supportive and inclusive community of like-minded individuals ready to empower you on your journey towards personal and professional fulfillment.
    `,
    link: '/services/workshops',
  },
  {
    bgColor: '#00c99f',
    title: 'Community Events',
    videoSrc: community,
    description: `Step into the vibrant tapestry of community engagement at Unity Horizons, where our events serve as catalysts for growth and connection. Each gathering is meticulously curated to uplift spirits, celebrate diversity, and foster empathy, creating a profound sense of belonging. Whether it's a culturally enriching festival, a collaborative volunteer drive, or an inclusive town hall meeting, every occasion is infused with purpose and passion.
    `,
    longerDescription: `Reflecting our steadfast commitment to empowering our community; These events provide opportunities to share stories, forge bonds, and transcend boundaries. Join us as we unite in inspiration and empowerment at Unity Horizons where the strength of our community knows no bounds.`,
    link: '/services/community-events',
  },
];
const Services = () => {
  return (
    <div className="services">
      <div className="services-container">
        <div className="service-banner">
          <ServicesVideoBanner />
          <div className="overlay">
            <h1 className="title">Our Programs and Services</h1>
          </div>
        </div>
        <div className="services-intro">
          <div className="content-wrapper">
            <p>
              Welcome to Unity Horizons, where endless opportunities await you.
              Explore our wide range of programs and services below, carefully
              crafted to ignite your passions, nurture your well-being, and
              empower your personal and collective growth. Our programs and
              services serve as lifelines, providing individuals with the tools
              to carve out their futures and break cycles of disparity, ensuring
              their place at the table of societal progress.
            </p>
          </div>

          {/* Animated arrow */}
          <motion.div
            className="arrow-down"
            animate={{y: [0, 10, 0]}} // Bounce animation
            transition={{duration: 1.5, repeat: Infinity}} // Animation duration and repeat
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="46"
              height="46"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M12 5v14M5 12l7 7 7-7" />
            </svg>
          </motion.div>
        </div>
        <div className="services-content-wrapper">
          {servicesData.map((service, index) => (
            <ServicesContent
              key={index}
              videoSrc={service.videoSrc}
              title={service.title}
              description={service.description}
              longerDescription={service.longerDescription}
              link={service.link}
              svg={service.svg}
              bgColor={service.bgColor}
            />
          ))}
        </div>
      </div>
      <div className="call-to-action-btn">
        <h1>Make a Difference, Volunteer Today!</h1>
        <Link to="/volunteer-signup">
          <Button>Get Involved</Button>
        </Link>
      </div>
    </div>
  );
};

export default Services;
