import React, {useRef} from 'react';
import {motion} from 'framer-motion';
import '../css/OurStory.css';
import {ourStoryFadeInAnimationVariant} from '../common/AnimationVariants';

const OurStory = () => {
  const ref = useRef(null);

  return (
    <div className="our__story">
      <div className="container">
        <motion.h2
          ref={ref}
          className="title"
          variants={ourStoryFadeInAnimationVariant}
          initial="initial"
          whileInView="animate"
          viewport={{once: true}}
          style={{color: '#ed891c'}}
        >
          UNITY HORIZONS CIC{' '}
          <span className="cic">(Community Interest Company)</span>
        </motion.h2>
        <div className="content-wrapper">
          <motion.div
            className="content"
            initial={{opacity: 0, x: 'auto'}}
            whileInView={{opacity: 1, x: 0}}
            transition={{
              delay: 0.05,
              duration: 2,
              type: 'tween',
              stiffness: 120,
            }}
            viewport={{once: true}}
          >
            <h3 className="title">WHO ARE WE?</h3>
            <p>
              At Unity Horizons, we're on a mission to empower and uplift
              marginalised communities. Our story begins with a shared vision of
              creating positive change, led by a diverse team of passionate
              professionals who believe in the power of unity to drive
              meaningful impact.
            </p>
            <p>
              Through our programs and services, we've made a real difference in
              the lives of individuals and communities facing challenges. From
              offering support to aspiring entrepreneurs to providing
              educational workshops and promoting mental health and well-being,
              our initiatives are designed to empower and uplift.
            </p>
          </motion.div>
          <motion.div
            className="content"
            initial={{opacity: 0, x: 'auto'}}
            whileInView={{opacity: 1, x: 0}}
            transition={{
              delay: 0.09,
              duration: 2,
              type: 'tween',
              stiffness: 120,
            }}
            viewport={{once: true}}
          >
            <h3 className="title">WHAT WE DO!</h3>
            <div className="list">
              <p>
                Collaboration is key to our approach. We actively partner with
                organisations, businesses, and government agencies to expand our
                reach and maximise our impact. Together, we leverage our
                collective resources and expertise to create lasting solutions
                and drive meaningful change.
              </p>
              <p>
                Looking ahead, our commitment to empowerment, inclusivity, and
                social change remains unwavering. We envision a future where
                everyone has the opportunity to thrive, and we're dedicated to
                continuing our work to make this vision a reality.
              </p>
              <p>
                Join us on our journey towards a more equitable and inclusive
                society.{' '}
                <strong>
                  Together, we can build a brighter future where diversity is
                  celebrated, voices are heard, and everyone has the opportunity
                  to succeed.
                </strong>
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
