import React from 'react';
import '../css/CommunityEvent.css';
import {Button} from '@material-ui/core';
import bannerImg from '../images/community-banner.jpg';
import EventServices from '../events-components/EventServices';
import {useNavigate} from 'react-router-dom';

const CommunityEvents = () => {
  const navigate = useNavigate();
  return (
    <div className="community-events">
      <div className="community-events-container">
        <div className="main-header">
          <div className="wrapper">
            <div className="main-header-content">
              <h1 className="title">Join Us for Community Events</h1>
              <p>Engaging Activities for Everyone </p>
            </div>
            <div className="btn">
              <Button
                className="bg-btn"
                onClick={() => navigate('/services/community-events/explore')}
              >
                Explore Events
              </Button>
              <Button
                className="border-btn"
                onClick={() => navigate('/volunteer-signup')}
              >
                Volunteer
              </Button>
            </div>
          </div>
          <div className="image">
            <img src={bannerImg} alt="Banner" />
          </div>
        </div>
        <EventServices />
      </div>
      <div className="call-to-action-btn">
        <h1>
          Join us for our upcoming community events and be a part of the vibrant
          and inclusive community spirit at Unity Horizons.
        </h1>
        <Button onClick={() => navigate('/membership-signup')}>Join Us</Button>
      </div>
    </div>
  );
};

export default CommunityEvents;
