import React, {useRef} from 'react';
import {Button} from '@material-ui/core';
import {motion} from 'framer-motion';
import {FaAngleRight} from 'react-icons/fa6';
import {Link} from 'react-router-dom';
import {ReusableVideoComponent} from '../common/VideoComponent';

import '../css/ServicesContent.css';

const ServicesContent = ({
  key,
  videoSrc,
  title,
  description,
  longerDescription,
  link,
  bgColor,
}) => {
  return (
    <motion.div className="services__content" key={key}>
      <div className="grid-container">
        <div className="grid-collection">
          <ReusableVideoComponent src={videoSrc} />
          <motion.div className="wrapper" style={{background: bgColor}}>
            <div className="content-section">
              <h2>{title}</h2>
            </div>
          </motion.div>
        </div>
        <motion.div
          className="grid-content"
          initial={{opacity: 0, x: 'auto'}}
          whileInView={{opacity: 1, x: 0}}
          transition={{
            delay: 0.05,
            duration: 2,
            type: 'tween',
            stiffness: 120,
          }}
          viewport={{once: true}}
        >
          <p>{description}</p>
          <p>{longerDescription}</p>
          <Link to={link}>
            <Button className="button">Learn More</Button>
            <FaAngleRight />
          </Link>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ServicesContent;
