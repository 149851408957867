import React, {useState} from 'react';
import axios from '../containers/axios';
import '../components/css/ContactUs.css';
import {Button} from '@material-ui/core';
import Error from '../components/common/Error'; // Import Error component
import Success from '../components/common/Success'; // Import Success component

const ContactUs = () => {
  // Define state variables for form fields, error, and success messages
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Make an HTTP POST request to your backend route
      const response = await axios.post('/submit-contact-form', {
        fullName: fullName,
        email: email,
        phoneNumber: phoneNumber,
        message: message,
      });

      setSuccess(response.data.message); // Handle success
      setError(null); // Reset error state
      setFullName('');
      setEmail('');
      setPhoneNumber('');
      setMessage('');
    } catch (error) {
      setError(error.response.data.message); // Set error message
      setSuccess(null); // Reset success state
    }
  };

  return (
    <div className="contact__us__container">
      <div className="contact__banner">
        <h2 className="title">Get in touch</h2>
        <p>
          Please don't hesitate to get in touch with us regarding our offerings
          and services, or for any assistance you may require.
        </p>
      </div>
      <div className="content-wrapper">
        <div className="address__section">
          <h2 className="title">Address</h2>
          <p>UNITY HORIZONS CIC</p>
          <p>Lytchett House, 13 Freeland Park</p>
          <p>Wareham Road</p>
          <p>Poole, Dorset, BH16 6FA</p>
          <h2 className="title">Phone</h2>
          <a href="tel:+447475566762">
            <p className="phone">+44 (0) 7475566762</p>
          </a>
          <h2 className="title">Email</h2>
          <a
            href="mailto:info@unityhorizons.com"
            target="_blank"
            rel="noreferrer"
          >
            <p className="email">info@unityhorizons.com</p>
          </a>
        </div>
        <div className="contact__form">
          <h2 className="title">Contact Us</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-content-wrapper">
              <div className="form__group">
                <label>
                  Full Name:
                  <input
                    type="text"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    required
                    placeholder="Full Name"
                  />
                </label>
              </div>
              <div className="form__group">
                <label>
                  Email
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Email"
                  />
                </label>
              </div>
              <div className="form__group">
                <label>
                  Phone Number
                  <input
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                    placeholder="Phone Number"
                  />
                </label>
              </div>
            </div>
            <div className="form__group">
              <label>
                Message
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  rows="4"
                  placeholder="Message"
                ></textarea>
              </label>
            </div>
            {error && <Error message={error} clearError={() => setError('')} />}{' '}
            {/* Display error message if error state is set */}
            {success && (
              <Success message={success} clearSuccess={() => setSuccess('')} />
            )}{' '}
            {/* Display success message if success state is set */}
            <Button className="submit__btn" type="submit">
              Submit
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
