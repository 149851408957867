import React, {useState, useEffect, useRef} from 'react';
import {motion} from 'framer-motion';

import {
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Menu,
  School,
  Sports,
  LocalLibrary,
  People,
} from '@material-ui/icons';
import {Link} from 'react-router-dom';
import Logo from '../images/final-logo.png';
import '../css/Nav.css';
import {Button} from '@material-ui/core';
import LargeScreenNav from './LargeScreenNav';

const Nav = () => {
  const [showServices, setShowServices] = useState(false);
  const [openMenu, SetOpenMenu] = useState(false);
  const [openSubMenu, SetSubOpenMenu] = useState(false);

  const handleServicesClick = () => {
    setShowServices(!showServices);
  };

  const toggleSubOpenMenu = () => {
    SetSubOpenMenu(!openSubMenu); // Toggles the state value
    handleServicesClick();
  };

  const [isNavVisible, setIsNavVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const navRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isVisible = prevScrollPos > currentScrollPos;

      setIsNavVisible(isVisible);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  return (
    <nav ref={navRef} className={`nav ${isNavVisible ? 'sticky' : ''}`}>
      {' '}
      <div className="container">
        <div className="logo">
          <Link to="/">
            <div className="img-wrapper">
              <img src={Logo} alt="" />
            </div>
          </Link>
          <div className="menu__icon">
            <Menu onClick={() => SetOpenMenu(true)} className="icon" />
          </div>
        </div>

        <div className="large-screen-menu-wrapper">
          <LargeScreenNav />
        </div>
        <motion.div
          className={`menu ${openMenu && 'showMenu'}`}
          initial={{opacity: 0, y: 100}}
          whileInView={{opacity: 1, y: 0}}
          transition={{
            delay: 0.05,
            duration: 1,
            type: 'spring',
            stiffness: 120,
          }}
        >
          <div className="menu-container">
            <div className="modal-logo">
              <Link onClick={() => SetOpenMenu(false)} to="/">
                <div className="img-wrapper">
                  <img src={Logo} alt="" />
                </div>
              </Link>
              <div className="icon__div">
                <Close onClick={() => SetOpenMenu(false)} className="icon" />
              </div>
            </div>
            <ul className="main-menu-list">
              <li onClick={() => SetOpenMenu(false)}>
                <Link to="/">Home</Link>
              </li>
              <li onClick={() => SetOpenMenu(false)}>
                <Link to="/about-us">About us</Link>
              </li>
              <li className="services__li">
                <div
                  className={`services__wrapper ${
                    showServices && 'underline__services__wrapper'
                  }`}
                >
                  <Link onClick={() => SetOpenMenu(false)} to="/services">
                    Services
                  </Link>
                  {openSubMenu ? (
                    <KeyboardArrowUp
                      onClick={toggleSubOpenMenu}
                      className={`icon ${openSubMenu && 'active__icon'}`}
                    />
                  ) : (
                    <KeyboardArrowDown
                      onClick={toggleSubOpenMenu}
                      className={`icon ${openSubMenu && 'active__icon'}`}
                    />
                  )}
                </div>

                {showServices && (
                  <ul
                    className={`sub__menu ${openSubMenu && 'show__sub__menu'}`}
                  >
                    <li>
                      <School className="icon" />
                      <Link
                        onClick={() => SetOpenMenu(false)}
                        to="/services/education"
                      >
                        Education
                      </Link>
                    </li>
                    <li>
                      <Sports className="icon" />
                      <Link
                        onClick={() => SetOpenMenu(false)}
                        to="/services/sports"
                      >
                        Health and Wellbeing
                      </Link>
                    </li>
                    <li>
                      <LocalLibrary className="icon" />
                      <Link
                        onClick={() => SetOpenMenu(false)}
                        to="/services/workshops"
                      >
                        Workshops
                      </Link>
                    </li>
                    <li>
                      <People className="icon" />
                      <Link
                        onClick={() => SetOpenMenu(false)}
                        to="/services/community-events"
                      >
                        Community Events
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li onClick={() => SetOpenMenu(false)}>
                <Link to="/blog">Blog</Link>
              </li>
              <li onClick={() => SetOpenMenu(false)}>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
            <div className="nav-call-to-action-btn">
              <Link
                className="member-btn-link"
                onClick={() => SetOpenMenu(false)}
                to="/membership-signup"
              >
                <Button>Become a Member</Button>
              </Link>
              <Link
                className="partner-btn-link"
                onClick={() => SetOpenMenu(false)}
                to="/partner-signup"
              >
                <Button>Partner With Us</Button>
              </Link>
              <Link
                className="volunteer-btn-link"
                onClick={() => SetOpenMenu(false)}
                to="/volunteer-signup"
              >
                <Button>Volunteer</Button>
              </Link>
            </div>
          </div>
        </motion.div>
      </div>
    </nav>
  );
};

export default Nav;
