import {Storefront} from '@material-ui/icons';
import React from 'react';
import '../css/Footer.css';
import {Link} from 'react-router-dom';
import Logo from '../images/final-logo.png';
function Footer() {
  return (
    <div className="footer">
      <footer className="container">
        <div className="logo">
          <Link to="/">
            <img src={Logo} alt="" />
          </Link>
        </div>
        <div className="group">
          <section className="footer-general content">
            <h3>General</h3>
            <ul>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <li>
                <Link to="/faqs">FAQs</Link>
              </li>
            </ul>
          </section>
          <section className="footer-services content">
            <h3>Business Activities</h3>
            <ul>
              <li>
                <Link to="/services/education">Education</Link>
              </li>
              <li>
                <Link to="/services/sports">Health and wellbeing</Link>
              </li>
              <li>
                <Link to="/services/workshops">Workshops</Link>
              </li>
              <li>
                <Link to="/services/community-events">Community Events</Link>
              </li>
            </ul>
          </section>
          <section className="footer-customer-service content">
            <h3>Customer Service</h3>
            <ul>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <Link to="/membership-signup">Become a Member</Link>
              </li>
              <li>
                <Link to="volunteer-signup">Volunteer</Link>
              </li>
            </ul>
          </section>
          <section className="footer-policies content">
            <h3>Policies</h3>
            <ul>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
              </li>
              <li>
                <Link to="/cookie-policy">Cookie Policy</Link>
              </li>
              <li>
                <Link to="/safeguarding-policy">Safeguarding Policy</Link>
              </li>
            </ul>
          </section>
        </div>
        <section className="copyright">
          <p>
            Unity Horizons CIC 2024 | Company Number 15516515 | made with love
            by{' '}
            <a
              href="https://services-fb54d.web.app/"
              target="_blank"
              rel="noreferrer"
            >
              skylinc
            </a>
          </p>
        </section>
      </footer>
    </div>
  );
}

export default Footer;
