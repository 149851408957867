import React from 'react';
import NewsLetter from './NewsLetter';
import Footer from './Footer';
import '../css/MainFooter.css';
function MainFooter() {
  return (
    <div className="main-footer">
      <div className="news-letter">
        <NewsLetter />
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

export default MainFooter;
