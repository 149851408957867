import React, {useState} from 'react';
import axios from '../containers/axios';
import '../components/css/Forms.css';
import Error from '../components/common/Error'; // Import Error component
import Success from '../components/common/Success'; // Import Success component
import {Link} from 'react-router-dom';

// Array of preferred volunteer roles
const volunteerRoles = [
  'Event Management',
  'Community Outreach',
  'Educational Tutoring',
  'Sports Coaching',
  'General Support',
  'Other',
];

const availabilityOptions = [
  {id: 'weekday', value: 'Weekdays', label: 'Weekdays'},
  {id: 'weekend', value: 'Weekends', label: 'Weekends'},
  {id: 'evening', value: 'Evenings', label: 'Evenings'},
];

const VolunteerSignup = () => {
  // Define state variables for form fields, error, and success messages
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [availability, setAvailability] = useState([]);
  const [preferredRoles, setPreferredRoles] = useState([]);
  const [volunteeringExperience, setVolunteeringExperience] = useState('');
  const [interestReason, setInterestReason] = useState('');
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleAvailabilityCheckboxChange = (event) => {
    const {value, checked} = event.target;
    setAvailability((prevState) =>
      checked
        ? [...prevState, value]
        : prevState.filter((item) => item !== value)
    );
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Make an HTTP POST request to your backend route
      const response = await axios.post('/submit-volunteer-form', {
        fullName: fullName,
        email: email,
        phoneNumber: phoneNumber,
        availability: availability,
        preferredRoles: preferredRoles,
        volunteeringExperience: volunteeringExperience,
        interestReason: interestReason,
        agreedTerms: agreedTerms,
      });

      setSuccess(response.data.message); // Handle success
      setError(null); // Reset error state
      setFullName('');
      setEmail('');
      setPhoneNumber('');
      setAvailability([]);
      setPreferredRoles([]);
      setVolunteeringExperience('');
      setInterestReason('');
      setAgreedTerms(false);
    } catch (error) {
      setError(error.response.data.message); // Set error message
      setSuccess(null); // Reset success state
    }
  };

  return (
    <div className="form-page volunteer-signUp">
      <div className="intro-section">
        <h1>Join Us as a Volunteer!</h1>
        <p>
          Make a difference in your community by volunteering with us! Fill out
          the form below to sign up as a volunteer.
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-content-wrapper">
          <label>
            Full Name:
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
              placeholder="Full Name"
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Email"
            />
          </label>
          <label>
            Phone Number:
            <input
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              placeholder="Digits only, no special characters"
            />
          </label>
          <label>
            Availability:
            <div className="checkbox-container">
              {availabilityOptions.map((option) => (
                <div key={option.id}>
                  <label className="checkbox-label" htmlFor={option.id}>
                    {option.label}
                    <input
                      type="checkbox"
                      id={option.id}
                      value={option.value}
                      checked={availability.includes(option.value)}
                      onChange={handleAvailabilityCheckboxChange}
                    />
                  </label>
                </div>
              ))}
            </div>
          </label>
          <label>
            Preferred Volunteer Roles:
            <div className="checkbox-container">
              {volunteerRoles.map((role) => (
                <label className="checkbox-label" htmlFor={role}>
                  {role}
                  <input
                    type="checkbox"
                    id={role}
                    value={role}
                    checked={preferredRoles.includes(role)}
                    onChange={(e) =>
                      setPreferredRoles((prevRoles) =>
                        e.target.checked
                          ? [...prevRoles, role]
                          : prevRoles.filter((r) => r !== role)
                      )
                    }
                  />
                </label>
              ))}
            </div>
          </label>
          <label>
            Do you have any previous volunteering experience?
            <div className="radio-container">
              <label className="radio-label" htmlFor="yes">
                Yes
                <input
                  type="radio"
                  id="yes"
                  name="partneredBefore"
                  value="Yes"
                  checked={volunteeringExperience === 'Yes'}
                  onChange={(e) => setVolunteeringExperience(e.target.value)}
                />
              </label>
              <label htmlFor="no">
                No
                <input
                  type="radio"
                  id="no"
                  name="partneredBefore"
                  value="No"
                  checked={volunteeringExperience === 'No'}
                  onChange={(e) => setVolunteeringExperience(e.target.value)}
                />
              </label>
            </div>
          </label>
        </div>
        <label className="lrg-textarea">
          Why are you interested in volunteering with us?
          <textarea
            value={interestReason}
            onChange={(e) => setInterestReason(e.target.value)}
            rows="4"
            placeholder="Interest Reason"
          ></textarea>
        </label>
        <label className="terms-checkbox-label">
          <p>
            I agree to the{' '}
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
          </p>
          <input
            type="checkbox"
            checked={agreedTerms}
            onChange={() => setAgreedTerms(!agreedTerms)}
            required
          />
        </label>
        {error && <Error message={error} clearError={() => setError('')} />}{' '}
        {/* Display error message if error state is set */}
        {success && (
          <Success message={success} clearSuccess={() => setSuccess('')} />
        )}{' '}
        {/* Display success message if success state is set */}
        <button className="submit-button" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default VolunteerSignup;
