import React from 'react';
import '../components/css/CookiePolicy.css';

const CookiePolicy = () => {
  return (
    <div className="cookie-policy">
      <div className="cookie-container">
        <h1>Cookie Policy</h1>
        <p>
          <strong>Regulatory Compliance:</strong> Our cookie policy complies
          with the General Data Protection Regulation (GDPR) and the Privacy and
          Electronic Communications Regulations (PECR) in the UK.
        </p>
        <p>
          <strong> User Experience:</strong> We aim to balance user privacy with
          convenience by providing clear and concise information about the
          cookies used on our website.
        </p>
        <h2>Nature of Cookies:</h2>
        <p>
          <strong>Essential Cookies:</strong> These cookies are necessary for
          the functioning of our website and cannot be disabled. They include
          cookies that enable basic functions like page navigation and access to
          secure areas of the website.
        </p>
        <p>
          <strong>Analytical Cookies:</strong> These cookies allow us to analyze
          user behavior on our website, improve user experience, and measure the
          performance of our website.
        </p>
        <p>
          <strong>Marketing Cookies:</strong> These cookies are used to
          personalize content and ads, provide social media features, and
          analyze our traffic.
        </p>
        <h2>Transparency and Control:</h2>
        <p>
          Users have the option to accept or reject non-essential cookies
          through our cookie consent banner.
        </p>
        <p>
          Detailed information about the types of cookies used, their purposes,
          durations, and any third-party cookies is provided in our cookie
          policy.
        </p>
        <h2>Review and Adaptation:</h2>
        <p>
          We regularly review and update our cookie policy to ensure compliance
          with regulations and best practices. Any changes to our cookie policy
          will be communicated to users, and renewed consent may be obtained if
          necessary.
        </p>
      </div>
    </div>
  );
};

export default CookiePolicy;
