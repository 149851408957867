import React from 'react';
import '../components/css/SafeguardingPolicy.css';

const SafeguardingPolicy = () => {
  return (
    <div className="safeguarding-policy">
      <div className="safeguarding-policy-container">
        <h1>Safeguarding Policy</h1>
        <h2>Introduction</h2>
        <p>
          Unity Horizons Community Interest Company (CIC) is dedicated to fostering a safe and inclusive environment for all individuals involved in our community-focused initiatives. This safeguarding policy underscores our unwavering commitment to preventing harm, safeguarding vulnerable individuals, and promoting the well-being of our community.
        </p>

        <h2>Scope</h2>
        <p>
          This policy applies to all individuals engaged in Unity Horizons CIC's activities, including employees, volunteers, participants, contractors, and visitors. It encompasses all aspects of safeguarding, including protection from harm, abuse, exploitation, discrimination, and harassment.
        </p>

        <h2>Principles</h2>
        <ul>
          <li><strong>Community Empowerment:</strong> We empower individuals to recognize and assert their rights, equipping them with the knowledge and resources to protect themselves and others.</li>
          <li><strong>Inclusivity:</strong> We value diversity and inclusion, ensuring that every individual, regardless of background or circumstance, is treated with respect and dignity.</li>
          <li><strong>Collaborative Partnership:</strong> We collaborate with local partners, stakeholders, and authorities to strengthen safeguarding measures and ensure coordinated responses to safeguarding concerns.</li>
          <li><strong>Transparency and Accountability:</strong> We are transparent about our safeguarding practices, fostering trust and accountability within our community.</li>
          <li><strong>Continuous Improvement:</strong> We continuously review and enhance our safeguarding policies and procedures to adapt to changing needs and emerging risks.</li>
        </ul>

        <h2>Roles and Responsibilities</h2>
        <ul>
          <li><strong>Board of Directors/Management:</strong> The board of directors/management is responsible for establishing and implementing safeguarding policies and procedures, providing oversight, and ensuring compliance with legal and regulatory requirements.</li>
          <li><strong>Safeguarding Officer/Designated Lead:</strong> A designated safeguarding officer/lead will oversee the implementation of safeguarding policies and procedures, provide advice and support, and coordinate responses to safeguarding concerns.</li>
          <li><strong>Employees and Volunteers:</strong> All employees and volunteers are responsible for familiarizing themselves with safeguarding policies and procedures, reporting any safeguarding concerns promptly, and adhering to Unity Horizons CIC's code of conduct.</li>
          <li><strong>Participants and Stakeholders:</strong> Participants and stakeholders are encouraged to raise any safeguarding concerns they may have, seek support when needed, and actively contribute to creating a safe and respectful environment.</li>
        </ul>

        <h2>Procedures</h2>
        <p>
          Our safeguarding procedures include:
        </p>
        <ul>
          <li>Conducting background checks for employees and volunteers working with vulnerable populations.</li>
          <li>Providing safeguarding training and awareness-raising sessions for employees, volunteers, and participants.</li>
          <li>Establishing clear reporting mechanisms for raising safeguarding concerns, including anonymous reporting options.</li>
          <li>Responding promptly and appropriately to safeguarding concerns, following established reporting, investigation, and support procedures.</li>
          <li>Maintaining confidentiality and respecting the rights and dignity of individuals involved in safeguarding processes.</li>
        </ul>

        <h2>Monitoring and Review</h2>
        <p>
          We will regularly monitor and review our safeguarding policies and procedures to ensure their effectiveness and compliance with relevant laws, regulations, and best practices. Feedback from stakeholders and lessons learned from safeguarding incidents will inform continuous improvement efforts.
        </p>

        <h2>Implementation</h2>
        <p>
          This safeguarding policy will be communicated to all employees, volunteers, participants, and stakeholders through various channels, including orientation sessions, training programs, website publication, and printed materials. It will be regularly reviewed and updated as needed to reflect changes in organizational practices and safeguarding requirements.
        </p>

        <h2>Reporting</h2>
        <p>
          Any concerns or incidents related to safeguarding should be reported immediately to the designated safeguarding officer/lead or another appropriate authority within Unity Horizons CIC. Reports can be made verbally, in writing, or through designated reporting channels and will be handled with confidentiality and sensitivity.
        </p>
      </div>
    </div>
  );
};

export default SafeguardingPolicy;
