import React, {useRef} from 'react';
import '../css/ServicesSection.css';
import {Link} from 'react-router-dom';
import {Button} from '@material-ui/core';
import {motion, useScroll, useTransform} from 'framer-motion';
import {fadeInAnimationVariant} from '../common/AnimationVariants';

const ServicesSection = ({
  id,
  imageSrc,
  buttonLink,
  buttonText,
  buttonColor,
}) => {
  const ref = useRef(null);
  const {scrollYProgress} = useScroll({
    target: ref,
    offset: ['start center', 'end start'],
  });

  const scaleSection = useTransform(scrollYProgress, [1, 0], [1, 1]);

  return (
    <div className="services-section-comp">
      <motion.div
        ref={ref}
        className="container"
        variants={fadeInAnimationVariant}
        initial="initial"
        whileInView="animate"
        style={{scale: scaleSection}}
        custom={id}
        viewport={{once: true}}
        key={id}
      >
        <div className="image">
          <Link className="btn-link" to={buttonLink}>
            <img src={imageSrc} alt="Section Image" />
          </Link>
        </div>
        <Link className="btn-link" to={buttonLink}>
          <Button className="button" style={{backgroundColor: buttonColor}}>
            {buttonText}
          </Button>
        </Link>
      </motion.div>
    </div>
  );
};

export default ServicesSection;
