import React from 'react';
import ShowCase from '../components/home-components/Showcase';
import ServicesSection from '../components/home-components/ServicesSection';
import education from '../components/images/landing-page-education-img.jpg';
import health from '../components/images/landing-page-health-img.jpg';
import workshops from '../components/images/landing-page-workshops-img.jpg';
import event from '../components/images/event.jpg';
import {Button} from '@material-ui/core';
import '../components/css/Home.css';
import {Link} from 'react-router-dom';
import VolunteersList from '../components/home-components/VolunteersList';
import HomePageAbout from '../components/home-components/HomePageAbout';
import Testimonials from '../components/home-components/Testimonials';

const sections = [
  {
    imageSrc: education,
    buttonLink: '/services/education',
    buttonText: 'Educational',
    buttonColor: '#ff6a6a',
  },
  {
    imageSrc: health,
    buttonLink: '/services/sports',
    buttonText: 'Health and Wellbeing',
    buttonColor: '#2e466f',
  },
  {
    imageSrc: workshops,
    buttonLink: '/services/workshops',
    buttonText: 'Workshops',
    buttonColor: '#ec915c',
  },
  {
    imageSrc: event,
    buttonLink: '/services/community-events',
    buttonText: 'Events',
    buttonColor: '#00c99f',
  },
];

const Home = () => {
  return (
    <div className="home">
      <div className="container">
        <div className="home-showcase">
          <ShowCase />
        </div>
        <div className="home-page-about-section">
          <HomePageAbout />
        </div>
        <div className="home-service-section-container">
          <div className="title-wrapper">
            <h1>Our Services</h1>
          </div>
          <div className="services-section">
            {sections.map((section, index) => (
              <ServicesSection
                key={index}
                id={index}
                imageSrc={section.imageSrc}
                buttonLink={section.buttonLink}
                buttonText={section.buttonText}
                buttonColor={section.buttonColor}
              />
            ))}
          </div>
        </div>
        {/* <div className="volunteer-section-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-5336 -860.665 375 28.66"
            preserveAspectRatio="none"
            className="svg-before"
          >
            <path d="M-5336-832v-28.665c44.843 5.161 148.374 18.515 215.063 22.482 55.945 3.327 108.932 5.277 159.936 5.756v.427z" />
          </svg>
          <div className="content-wrapper">
            <div className="title-wrapper">
              <h1>Meet Our Team</h1>
            </div>
            <VolunteersList />
          </div>
        </div> */}
        <div className="home-testimonials-section">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-5336 -860.665 375 28.66"
            preserveAspectRatio="none"
            className="svg-before"
          >
            <path d="M-5336-832v-28.665c44.843 5.161 148.374 18.515 215.063 22.482 55.945 3.327 108.932 5.277 159.936 5.756v.427z" />
          </svg>
          <div className="title-wrapper">
            <h1>What Our Community Say</h1>
          </div>
          <div className="content-wrapper">
            <Testimonials />
          </div>
        </div>
        {/* <NewsSection /> */}
        <div className="call-to-action-btn">
          <h1>Embark on a Journey of Learning and Discovery</h1>
          <Button>
            <Link to="/services">Explore Services</Link>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Home;
