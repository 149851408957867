import React, {useRef} from 'react';
import {Button} from '@material-ui/core';
import {motion} from 'framer-motion';
import '../css/ExploreEventCard.css'; // Update the path to your ExploreEventCard CSS file
import {FaExternalLinkAlt} from 'react-icons/fa';
import {FaLocationArrow, FaCalendarAlt, FaCoins} from 'react-icons/fa';
import {FaClockRotateLeft} from 'react-icons/fa6';

const ExploreEventCard = ({course}) => {
  const ref = useRef(null);
  return (
    <motion.div
      ref={ref}
      className="explore-event-card"
      initial={{opacity: 0, x: 'auto'}}
      whileInView={{opacity: 1, x: 0}}
      transition={{
        delay: 0.05,
        duration: 2,
        type: 'tween',
        stiffness: 120,
      }}
    >
      <div className="event-img-div">
        <a
          href={course.eventURL}
          target="_blank"
          rel="noopener noreferrer"
          className="event-image"
        >
          <img src={course.imageUrl} alt={course.title} />
        </a>
      </div>
      <div className="event-header">
        <h2>{course.title}</h2>
        <p>{course.description}</p>
      </div>
      <div className="event-details">
        <Button
          variant="contained"
          component="a"
          href={course.eventURL}
          target="_blank"
          rel="noopener noreferrer"
          endIcon={<FaExternalLinkAlt />}
        >
          Explore Event
        </Button>
        <div className="line-break"></div>
        <div className="event-content-container">
          {course.cost && (
            <div className="event-content event-cost-wrapper">
              <FaCoins />
              <span>{course.cost}</span>
            </div>
          )}
          {course.date && (
            <div className="event-content event-date-wrapper">
              <FaCalendarAlt />
              <span>{course.date}</span>
            </div>
          )}
          {course.location && (
            <div className="event-content event-location-wrapper">
              <FaLocationArrow />
              <span>{course.location}</span>
            </div>
          )}
          {course.duration && (
            <div className="event-content event-location-wrapper">
              <FaClockRotateLeft />
              <span>{course.duration}</span>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default ExploreEventCard;
