import React from 'react';
import {Link} from 'react-router-dom';
import '../components/css/TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <div className="terms-and-conditions-container">
        <h1>Terms and Conditions</h1>
        <p>
          Welcome to Unity Horizons CIC. These terms and conditions outline the
          rules and regulations for the use of Unity Horizons CIC's Website.
        </p>

        <br />

        <h2>
          By accessing this website we assume you accept these terms and
          conditions.
        </h2>
        <p>
          Do not continue to use Unity Horizons CIC's website if you do not
          accept all of the terms and conditions stated on this page.
        </p>

        <br />

        <h2>Cookies</h2>
        <p>
          We employ the use of cookies. By accessing Unity Horizons CIC's
          website, you agreed to use cookies in agreement with the{' '}
          <Link to="/cookie-policy">Cookie Policy</Link>.
        </p>

        <br />

        <h2>License</h2>
        <p>
          Unless otherwise stated, Unity Horizons CIC and/or its licensors own
          the intellectual property rights for all material on Unity Horizons
          CIC. All intellectual property rights are reserved. You may access
          this from Unity Horizons CIC for your own personal use subjected to
          restrictions set in these terms and conditions.
        </p>

        <h2>You must not:</h2>
        <ul>
          <li>Republish material from Unity Horizons CIC</li>
          <li>Sell, rent or sub-license material from Unity Horizons CIC</li>
          <li>Reproduce, duplicate or copy material from Unity Horizons CIC</li>
          <li>Redistribute content from Unity Horizons CIC</li>
        </ul>

        <h2>
          Parts of this website offer an opportunity for users to post and
          exchange opinions and information in certain areas of the website.
          Unity Horizons CIC does not filter, edit, publish or review Comments
          prior to their presence on the website. Comments do not reflect the
          views and opinions of Unity Horizons CIC,its agents and/or affiliates.
          Comments reflect the views and opinions of the person who post their
          views and opinions. To the extent permitted by applicable laws, Unity
          Horizons CIC shall not be liable for the Comments or for any
          liability, damages or expenses caused and/or suffered as a result of
          any use of and/or posting of and/or appearance of the Comments on this
          website.
        </h2>

        <p>
          Unity Horizons CIC reserves the right to monitor all Comments and to
          remove any Comments which can be considered inappropriate, offensive
          or causes breach of these Terms and Conditions.
        </p>

        <br />

        <h2>You warrant and represent that:</h2>
        <ul>
          <li>
            You are entitled to post the Comments on our website and have all
            necessary licenses and consents to do so;
          </li>
          <li>
            The Comments do not invade any intellectual property right,
            including without limitation copyright, patent or trademark of any
            third party;
          </li>
          <li>
            The Comments do not contain any defamatory, libelous, offensive,
            indecent or otherwise unlawful material which is an invasion of
            privacy
          </li>
          <li>
            The Comments will not be used to solicit or promote business or
            custom or present commercial activities or unlawful activity.
          </li>
        </ul>

        <p>
          You hereby grant Unity Horizons CIC a non-exclusive license to use,
          reproduce, edit and authorize others to use, reproduce and edit any of
          your Comments in any and all forms, formats or media.
        </p>

        <br />

        <h2>Hyperlinking to our Content</h2>
        <ul>
          <li>
            The following organisations may link to our Website without prior
            written approval:
            <ul>
              <li>Government agencies;</li>
              <li>Search engines;</li>
              <li>News organizations;</li>
              <li>
                Online directory distributors may link to our Website in the
                same manner as they hyperlink to the Websites of other listed
                businesses; and
              </li>
              <li>
                System wide Accredited Businesses except soliciting non-profit
                organisations, charity shopping malls, and charity fundraising
                groups which may not hyperlink to our Web site.
              </li>
            </ul>
          </li>
          <li>
            These organisations may link to our home page, to publications or to
            other Website information so long as the link: (a) is not in any way
            deceptive; (b) does not falsely imply sponsorship, endorsement or
            approval of the linking party and its products and/or services; and
            (c) fits within the context of the linking party’s site.
          </li>
        </ul>

        {/* Add more content and links as necessary */}

        <h2>Content Liability</h2>
        <p>
          We shall not be hold responsible for any content that appears on your
          Website. You agree to protect and defend us against all claims that is
          rising on your Website. No link(s) should appear on any Website that
          may be interpreted as libelous, obscene or criminal, or which
          infringes, otherwise violates, or advocates the infringement or other
          violation of, any third party rights.
        </p>

        <br />

        <h2>Reservation of Rights</h2>
        <p>
          We reserve the right to request that you remove all links or any
          particular link to our Website. You approve to immediately remove all
          links to our Website upon request. We also reserve the right to amen
          these terms and conditions and it’s linking policy at any time. By
          continuously linking to our Website, you agree to be bound to and
          follow these linking terms and conditions.
        </p>

        <br />

        <h2>Removal of links from our website</h2>
        <p>
          If you find any link on our Website that is offensive for any reason,
          you are free to contact and inform us any moment. We will consider
          requests to remove links but we are not obligated to or so or to
          respond to you directly.
        </p>

        <br />

        <h2>Disclaimer</h2>
        <p>
          To the maximum extent permitted by applicable law, we exclude all
          representations, warranties and conditions relating to our website and
          the use of this website. Nothing in this disclaimer will:
        </p>

        <ul>
          <li>
            limit or exclude our or your liability for death or personal injury;
          </li>
          <li>
            limit or exclude our or your liability for fraud or fraudulent
            misrepresentation;
          </li>
          <li>
            limit any of our or your liabilities in any way that is not
            permitted under applicable law; or
          </li>
          <li>
            exclude any of our or your liabilities that may not be excluded
            under applicable law.
          </li>
        </ul>

        <p>
          The limitations and prohibitions of liability set in this Section and
          elsewhere in this disclaimer: (a) are subject to the preceding
          paragraph; and (b) govern all liabilities arising under the
          disclaimer, including liabilities arising in contract, in tort and for
          breach of statutory duty.
        </p>

        <p>
          As long as the website and the information and services on the website
          are provided free of charge, we will not be liable for any loss or
          damage of any nature.
        </p>

        <br />

        <h2>Privacy Policy</h2>
        <p>
          For more information, please read our{' '}
          <Link to="/privacy-policy">Privacy Policy</Link>.
        </p>

        <br />

        <h2>Contact Information</h2>
        <p>
          If you have any questions or concerns about these Terms and
          Conditions, please contact us at:
        </p>
        <div className="address__section">
          <h2 className="title">Address</h2>
          <p>UNITY HORIZONS CIC</p>
          <p>Lytchett House, 13 Freeland Park</p>
          <p>Wareham Road</p>
          <p>Poole, Dorset, BH16 6FA</p>
          <h2 className="title">Phone</h2>
          <p className="phone">+44 (0) 7475566762</p>
          <h2 className="title">Email</h2>
          <p className="email">info@unityhorizons.com</p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
