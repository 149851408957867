import React, {useEffect, useState} from 'react';
import BlogPost from '../components/blog/BlogPosts';
import blogPosts from '../components/blog/blogPostsData.json';
import {useLocation, useNavigate} from 'react-router-dom';
import Banner from '../components/images/blog-banner.jpg';
import '../components/css/Blog.css';
import SubscribeToBlog from '../components/blog/SubscribeToBlog';

const Blog = () => {
  const postsPerPage = 5;
  const navigate = useNavigate();
  const location = useLocation();

  // Extract page number from URL parameter or default to 1
  const searchParams = new URLSearchParams(location.search);
  const currentPageFromURL = parseInt(searchParams.get('page')) || 1;

  const [currentPage, setCurrentPage] = useState(currentPageFromURL - 1); // Subtract 1 to match zero-based index
  const [pageNumbers, setPageNumbers] = useState([]);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);

  useEffect(() => {
    // Update the URL parameter when currentPage changes
    navigate(`/blog?page=${currentPage + 1}`, {replace: true});
  }, [currentPage, navigate]);

  useEffect(() => {
    // Update page numbers based on the current page and total pages
    const pageCount = Math.ceil(blogPosts.length / postsPerPage);
    const maxDisplayedPages = 5; // Maximum number of pages to display

    let startPage = Math.max(
      0,
      currentPage - Math.floor(maxDisplayedPages / 2)
    );
    let endPage = Math.min(pageCount - 1, startPage + maxDisplayedPages - 1);

    // Adjust start and end page if less than maxDisplayedPages are available
    if (endPage - startPage + 1 < maxDisplayedPages) {
      startPage = Math.max(0, endPage - maxDisplayedPages + 1);
    }

    setPageNumbers(
      Array.from(
        {length: endPage - startPage + 1},
        (_, index) => startPage + index
      )
    );

    setIsFirstPage(currentPage === 0);
    setIsLastPage(currentPage === pageCount - 1);
  }, [currentPage, blogPosts.length, postsPerPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const sectionRef = document.getElementById('subscribe-to-blog'); // Assuming the section has an id of 'about-section'
    sectionRef.scrollIntoView({behavior: 'smooth'});
  };

  const offset = currentPage * postsPerPage;
  const currentPosts = blogPosts.slice(offset, offset + postsPerPage);

  return (
    <div className="blog">
      <div className="container">
        <div className="blog-banner">
          <img src={Banner} alt="" />
          <div className="overlay">
            <h1 className="title">Explore Our Blog</h1>
          </div>
        </div>
        <div className="blog-newsletter">
          <SubscribeToBlog />
        </div>
        <div className="blog-posts">
          {currentPosts.map((post) => (
            <BlogPost key={post.id} post={post} />
          ))}
        </div>
        <div className="pagination-container">
          <ul className="pagination">
            {!isFirstPage && (
              <li onClick={() => handlePageChange(currentPage - 1)}>&#8592;</li>
            )}
            {pageNumbers.map((pageNumber) => (
              <li
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber)}
                className={currentPage === pageNumber ? 'active' : ''}
              >
                {pageNumber + 1}
              </li>
            ))}
            {!isLastPage && (
              <li onClick={() => handlePageChange(currentPage + 1)}>&#8594;</li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Blog;
