import React from 'react';
import About from '../components/about-components/About';

const AboutUs = () => {
  return (
    <div>
      <About />
    </div>
  );
};

export default AboutUs;
