import React, {useState} from 'react';
import axios from '../../containers/axios';
import '../css/NewsLetter.css';
import {Button} from '@material-ui/core';
import {Facebook, Instagram, LinkedIn, YouTube} from '@material-ui/icons'; // Import icons
import Error from './Error'; // Import Error component
import Success from './Success'; // Import Success component
import {Link} from 'react-router-dom';

function NewsLetter() {
  // Define state variables for form fields, error, and success messages
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Make an HTTP POST request to your backend route
      const response = await axios.post('/subscribe-to-newsletter', {
        email: email,
      });

      setSuccess(response.data.message); // Handle success
      setError(null); // Reset error state
      setEmail('');
    } catch (error) {
      setError(error.response.data.message); // Set error message
      setSuccess(null); // Reset success state
    }
  };

  return (
    <div className="newsletter">
      <div className="container">
        <div className="group">
          <div className="title">
            <h2>
              Subscribe To Our{' '}
              <span className="newsletter-color">Newsletter</span>
            </h2>
            <p>Stay Connected, Join Our Newsletter for Community Support!</p>
          </div>
          <div className="input">
            {error && <Error message={error} clearError={() => setError('')} />}{' '}
            {/* Display error message if error state is set */}
            {success && (
              <Success message={success} clearSuccess={() => setSuccess('')} />
            )}{' '}
            {/* Display success message if success state is set */}
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter your email"
              />
              <Button type="submit">Subscribe</Button>
            </form>
          </div>
        </div>
        <div className="socials">
          <Link to="https://www.linkedin.com/company/unity-horizons-cic/">
            <LinkedIn className="icon" />
          </Link>
          <Link to="https://www.instagram.com/unityhorizonscic?igsh=MzRlODBiNWFlZA==">
            <Instagram className="icon" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NewsLetter;
