import React from 'react';
import { Facebook, Instagram, YouTube } from '@material-ui/icons';
import '../css/Header.css';
function Header() {
  return (
    <div className="main__header">
      <div className="container">
        <div className="contact">
          <p>info@unityHorizons.com</p>
          <p>07494498323</p>
        </div>
        <div className="socials">
          <Facebook className="icon facebook" />
          <Instagram className="icon insta" />
          <YouTube className="icon youtube" />
        </div>
      </div>
    </div>
  );
}

export default Header;
