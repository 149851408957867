import React from 'react';
import {motion} from 'framer-motion';
import {
  FaChalkboardTeacher,
  FaBookReader,
  FaLanguage,
  FaDraftingCompass,
  FaCalendarAlt,
  FaUsersCog,
  FaGraduationCap,
} from 'react-icons/fa';

import {fadeInAnimationVariant} from '../common/AnimationVariants';

import '../css/EducationServices.css';

function EducationService({icon, title, description, index}) {
  return (
    <li className="education-service">
      <motion.div
        className="icon-div"
        initial={{opacity: 0, y: 20}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 0.5}}
      >
        <motion.div
          className="line"
          variants={fadeInAnimationVariant}
          initial="initial"
          whileInView="animate"
        />
        <div className="icon-wrapper">{icon}</div>
      </motion.div>
      <div className="content-wrapper">
        <span className="content-title">{title}</span>
        <span className="content-desc">{description}</span>
      </div>
    </li>
  );
}

function EducationServices() {
  return (
    <div className="education-services">
      <div className="sub-header">
        <h1 className="title">Our Education Services</h1>
        <p>Unlocking Potential, Igniting Futures</p>
      </div>
      <div className="content">
        <ul>
          <EducationService
            index={0}
            icon={<FaChalkboardTeacher className="icon" />}
            title="Workshops and Seminars"
            description="Dive into a world of knowledge with our diverse array of workshops and seminars. From financial literacy to career development, personal growth, and academic success, our interactive sessions provide practical skills and valuable insights to participants, empowering them to navigate life's challenges with confidence and competence."
          />
          <EducationService
            index={1}
            icon={<FaBookReader className="icon" />}
            title="Tutoring and Academic Support"
            description="Elevate your academic journey with our comprehensive tutoring and academic support services. Whether you're a student of any age, our tailored programs offer one-on-one tutoring sessions, group study sessions, and homework assistance programs to help you excel academically and reach your full potential."
          />
          <EducationService
            index={2}
            icon={<FaLanguage className="icon" />}
            title="Skill-building Courses"
            description="Embark on a journey of personal and professional growth with our skill-building courses. From computer literacy and language learning to vocational training and entrepreneurship, our courses empower individuals to develop new skills or enhance existing ones, opening doors to new opportunities and improving overall well-being."
          />
          <EducationService
            index={3}
            icon={<FaDraftingCompass className="icon" />}
            title="Educational Resources"
            description="Access a wealth of knowledge and information at our educational resource center. From books and online resources to educational materials, our comprehensive collection supports lifelong learning and personal development, providing community members with the tools they need to thrive in an ever-changing world."
          />
          <EducationService
            index={4}
            icon={<FaCalendarAlt className="icon" />}
            title="Educational Events"
            description="Engage in stimulating conversations and learning experiences at our educational events. From guest speaker presentations to panel discussions and educational fairs, our events bring together experts, educators, and community members for meaningful learning and networking opportunities, fostering collaboration and collective growth."
          />
          <EducationService
            index={5}
            icon={<FaUsersCog className="icon" />}
            title="Parent and Family Engagement"
            description="Empower parents and families in their vital role as primary educators with our workshops and programs. From parenting workshops to family literacy programs and parent-teacher collaboration initiatives, we support family involvement in education, strengthening bonds and enhancing student success."
          />
          <EducationService
            index={6}
            icon={<FaGraduationCap className="icon" />}
            title="University and Career Readiness"
            description="Prepare for the next chapter of your academic and professional journey with our university and career readiness services. Whether you're navigating university admissions, exam season, or career planning, our support services offer guidance and resources to empower you to achieve your educational and professional goals."
          />
        </ul>
      </div>
    </div>
  );
}

export default EducationServices;
