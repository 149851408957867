import React from 'react';
import {motion} from 'framer-motion';
import {
  FaUsers,
  FaHandsHelping,
  FaMicrophone,
  FaTree,
  FaPalette,
  FaHeartbeat,
  FaChild,
} from 'react-icons/fa';

import {fadeInAnimationVariant} from '../common/AnimationVariants';

import '../css/EventServices.css';

function EventService({icon, title, description, index}) {
  return (
    <li className="event-service">
      <motion.div
        className="icon-div"
        initial={{opacity: 0, y: 20}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 0.5}}
      >
        <motion.div
          className="line"
          variants={fadeInAnimationVariant}
          initial="initial"
          whileInView="animate"
        />
        <div className="icon-wrapper">{icon}</div>
      </motion.div>
      <div className="content-wrapper">
        <span className="content-title">{title}</span>
        <span className="content-desc">{description}</span>
      </div>
    </li>
  );
}

function EventServices() {
  return (
    <div className="events-services">
      <div className="sub-header">
        <h1 className="title">Popular Community Events</h1>
        <p>
          Our community events encompass a wide range of activities, including:
        </p>
      </div>
      <div className="content">
        <ul>
          <EventService
            index={0}
            icon={<FaUsers className="icon" />}
            title="Cultural Festivals:"
            description="Experience the diverse tapestry of cultures within our community through vibrant festivals celebrating traditional arts, cultural performances, and cuisine from around the world."
          />
          <EventService
            index={1}
            icon={<FaHandsHelping className="icon" />}
            title="Volunteer Drives:"
            description="Join us in giving back to our community through volunteer drives that support local charities, schools, and organisations, making a positive impact on the lives of those in need."
          />
          <EventService
            index={2}
            icon={<FaMicrophone className="icon" />}
            title="Town Hall Meetings:"
            description="Engage in open dialogue and collaborative problem-solving at our town hall meetings, where community members come together to discuss important issues, share ideas, and work towards solutions."
          />
          <EventService
            index={3}
            icon={<FaTree className="icon" />}
            title="Environmental Initiatives:"
            description="Take part in environmental clean-up efforts, tree planting events, and sustainability workshops aimed at preserving our natural resources and creating a healthier, greener community."
          />
          <EventService
            index={4}
            icon={<FaPalette className="icon" />}
            title="Art Exhibitions:"
            description="Explore the creativity and talent within our community at art exhibitions featuring works by local artists, providing a platform for expression, inspiration, and connection."
          />
          <EventService
            index={5}
            icon={<FaHeartbeat className="icon" />}
            title="Wellness Workshops"
            description="Prioritise your well-being with workshops focused on mindfulness, stress reduction, and holistic health practices, promoting a sense of balance and vitality within our community."
          />
          <EventService
            index={6}
            icon={<FaChild className="icon" />}
            title="Family Fun Days:"
            description="Enjoy quality time with loved ones at family fun days filled with games, activities, and entertainment for all ages, fostering a sense of belonging and togetherness among community members."
          />
        </ul>
      </div>
    </div>
  );
}

export default EventServices;
