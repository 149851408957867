import React from 'react';
import {motion} from 'framer-motion';
import {
  FaDumbbell,
  FaRunning,
  FaBrain,
  FaUtensils,
  FaMountain,
  FaHeartbeat,
  FaUsers,
} from 'react-icons/fa';

import {fadeInAnimationVariant} from '../common/AnimationVariants';

import '../css/SportsServices.css';

function SportService({icon, title, description, index}) {
  return (
    <li className="sport-service">
      <motion.div
        className="icon-div"
        initial={{opacity: 0, y: 20}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 0.5}}
      >
        <motion.div
          className="line"
          variants={fadeInAnimationVariant}
          initial="initial"
          whileInView="animate"
        />
        <div className="icon-wrapper">{icon}</div>
      </motion.div>
      <div className="content-wrapper">
        <span className="content-title">{title}</span>
        <span className="content-desc">{description}</span>
      </div>
    </li>
  );
}

function SportServices() {
  return (
    <div className="sports-services">
      <div className="sub-header">
        <h1 className="title">Our Health and Wellbeing Services</h1>
        <p>Embark on a Journey to Holistic Health and Happiness</p>
      </div>
      <div className="content">
        <ul>
          <SportService
            index={0}
            icon={<FaDumbbell className="icon" />}
            title="Fitness Classes:"
            description="Join our expert instructors for a variety of fitness classes, including yoga, pilates, and cardio workouts, designed to strengthen your body and invigorate your spirit."
          />
          <SportService
            index={1}
            icon={<FaRunning className="icon" />}
            title="Sports Activities:"
            description="Engage in a range of sports activities such as basketball, football, and volleyball, fostering teamwork, physical fitness, and enjoyment of the game."
          />
          <SportService
            index={2}
            icon={<FaBrain className="icon" />}
            title="Mindfulness Workshops:"
            description="Explore the practice of mindfulness through our workshops, where you'll learn techniques to reduce stress, increase self-awareness, and cultivate inner peace."
          />
          <SportService
            index={3}
            icon={<FaUtensils className="icon" />}
            title="Nutrition Counseling:"
            description="Meet with our certified nutritionists for personalised guidance on healthy eating habits, meal planning, and nutritional support tailored to your individual needs."
          />
          <SportService
            index={4}
            icon={<FaMountain className="icon" />}
            title="Wellness Retreats:"
            description="Escape the hustle and bustle of daily life and join us for rejuvenating wellness retreats, where you'll immerse yourself in nature, engage in mindfulness practices, and nourish your body with nutritious meals."
          />
          <SportService
            index={5}
            icon={<FaHeartbeat className="icon" />}
            title="Holistic Healing Therapies:"
            description="Experience the benefits of holistic healing modalities such as acupuncture, massage therapy, and reiki, provided by our skilled practitioners to promote relaxation, balance, and overall well-being."
          />
          <SportService
            index={6}
            icon={<FaUsers className="icon" />}
            title="Community Wellness Events:"
            description="Participate in our community wellness events, including health fairs, wellness workshops, and group fitness challenges, designed to inspire and empower individuals to prioritise their health and well-being."
          />
        </ul>
      </div>
    </div>
  );
}

export default SportServices;
