import React from 'react';
import {motion} from 'framer-motion';
import {
  FaHandshake,
  FaUserTie,
  FaPaintBrush,
  FaRegLightbulb,
  FaMoneyBillAlt,
  FaPeopleArrows,
  FaHandsHelping,
} from 'react-icons/fa';

import {fadeInAnimationVariant} from '../common/AnimationVariants';

import '../css/WorkshopServices.css';

function WorkshopService({icon, title, description, index}) {
  return (
    <li className="workshop-service">
      <motion.div
        className="icon-div"
        initial={{opacity: 0, y: 20}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 0.5}}
      >
        <motion.div
          className="line"
          variants={fadeInAnimationVariant}
          initial="initial"
          whileInView="animate"
        />
        <div className="icon-wrapper">{icon}</div>
      </motion.div>
      <div className="content-wrapper">
        <span className="content-title">{title}</span>
        <span className="content-desc">{description}</span>
      </div>
    </li>
  );
}

function WorkshopServices() {
  return (
    <div className="workshops-services">
      <div className="sub-header">
        <h1 className="title">Our Workshop Services</h1>
        <p>Empowering Communities and Creating Positive Change</p>
      </div>
      <div className="content">
        <ul>
          <WorkshopService
            index={0}
            icon={<FaHandshake className="icon" />}
            title="Personal Growth:"
            description="Explore workshops designed to enhance self-awareness, boost confidence, and foster resilience, equipping you with tools to navigate life's challenges with grace and determination."
          />
          <WorkshopService
            index={1}
            icon={<FaUserTie className="icon" />}
            title="Professional Development:"
            description="Elevate your career with workshops focused on communication, leadership, project management, and other essential skills needed to excel in today's competitive workplace."
          />
          <WorkshopService
            index={2}
            icon={<FaPaintBrush className="icon" />}
            title="Creative Expression:"
            description="Unleash your creativity with workshops on art, writing, photography, and other forms of expression, providing a space for exploration, inspiration, and artistic growth."
          />
          <WorkshopService
            index={3}
            icon={<FaRegLightbulb className="icon" />}
            title="Wellness and Mindfulness:"
            description="Nurture your well-being with workshops that promote mindfulness, stress management, healthy living, and holistic health practices, helping you achieve balance and inner peace."
          />
          <WorkshopService
            index={4}
            icon={<FaMoneyBillAlt className="icon" />}
            title="Financial Empowerment:"
            description="Take control of your finances with workshops covering budgeting, saving, investing, and financial planning, empowering you to build a secure financial future."
          />
          <WorkshopService
            index={5}
            icon={<FaPeopleArrows className="icon" />}
            title="Diversity and Inclusion:"
            description="Deepen your understanding of diversity, equity, and inclusion through workshops that encourage dialogue, promote empathy, and foster inclusive communities."
          />
          <WorkshopService
            index={6}
            icon={<FaHandsHelping className="icon" />}
            title="Community Engagement:"
            description="Get involved and make a difference in your community with workshops focused on volunteerism, social activism, and community building, empowering you to be a catalyst for positive change."
          />
        </ul>
      </div>
    </div>
  );
}

export default WorkshopServices;
