import React from 'react';
import '../components/css/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <div className="privacy-policy-container">
        <h1>Privacy Policy</h1>
        <h2>Overview</h2>
        <p>
          Privacy Policy for Unity Horizons CIC
          <br />
          <strong>Last Updated:</strong> 29/01/2024
        </p>

        <h2>Purpose of this Privacy Policy:</h2>
        <p>
          Unity Horizons CIC, a limited company registered in the United
          Kingdom, is committed to safeguarding the privacy of individuals who
          engage with our community-focused initiatives. This Privacy Policy
          explains how we collect, use, and protect your personal information
          during your interactions with Unity Horizons CIC, including through
          our events, activities, and online platforms.
        </p>

        <h2>Contact Information:</h2>
        <div className="address__section">
          <h2 className="title">Address</h2>
          <p>UNITY HORIZONS CIC</p>
          <p>Lytchett House, 13 Freeland Park</p>
          <p>Wareham Road</p>
          <p>Poole, Dorset, BH16 6FA</p>
          <h2 className="title">Phone</h2>
          <p className="phone">+44 (0) 7475566762</p>
          <h2 className="title">Email</h2>
          <p className="email">info@unityhorizons.com</p>
        </div>

        <h2>Personal Information We Collect:</h2>
        <p>
          We collect basic personal information about individuals who engage
          with Unity Horizons CIC, including but not limited to:
          <br />
          - Names
          <br />
          - Contact details (email, phone)
          <br />
          - Information provided voluntarily through our events and online
          interactions
          <br />- Other relevant information necessary for specific events or
          activities
        </p>

        <h2>Why We Collect Personal Information:</h2>
        <p>
          We collect and process your personal information to:
          <br />
          - Facilitate communication and participation in our events
          <br />
          - Tailor our activities to address local needs
          <br />
          - Enhance community connections and collaboration
          <br />- Maintain and improve our services
        </p>

        <h2>How We Use Personal Information:</h2>
        <p>
          Your personal information is used for the purposes mentioned above and
          will be stored securely in our internal systems. We may share data
          with third parties for event-related purposes, ensuring your
          information is handled responsibly.
        </p>

        <h2>Data Retention:</h2>
        <p>
          We retain your personal information for as long as necessary for the
          purposes stated in this Privacy Policy and as required by applicable
          laws. You may have control over data retention for certain services,
          where feasible.
        </p>

        <h2>Your Legal Rights:</h2>
        <p>
          As an individual, you have rights regarding your personal information,
          including the right to access, correct, or delete your data. You can
          also object to or restrict the processing of your information.
        </p>

        <h2>How to Lodge a Complaint:</h2>
        <p>
          If you have concerns about how your personal information is handled,
          please contact us. You also have the right to lodge a complaint with
          the UK Information Commissioner's Office.
        </p>

        <h2>Updates to Privacy Policy:</h2>
        <p>
          Unity Horizons CIC may update this Privacy Policy periodically. The
          latest version will be available on our website.
        </p>

        <p>
          Thank you for engaging with Unity Horizons CIC. Your privacy is
          important to us.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
