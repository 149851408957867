import React from 'react';
import {Button} from '@material-ui/core'; // Assuming Button is imported from Material-UI
import khalidImg from '../images/kay-img.jpg';
import diniImg from '../images/dini-img.jpg';
import joharImg from '../images/johar-img.jpg';
import mohamedImg from '../images/mibsako-img.jpg';
import hammuniImg from '../images/hammuni-img.jpg';
import hamseImg from '../images/hamse-img.jpg';
import aqImg from '../images/aq-img.jpg';
import samImg from '../images/sam-img.jpg';
import iliasImg from '../images/ilias-img.jpg';
import unKnown from '../images/unknown.png';
import '../css/OurTeam.css';

// Define data for executive team members
const executiveTeamMembers = [
  {
    name: 'Omar Dini',
    role: 'Chief Executive',
    imgSrc: diniImg,
  },
  {
    name: 'Khalid Ibrahim',
    role: 'Chief Operating Officer',
    imgSrc: khalidImg,
  },
  {
    name: 'Mohamed Abdalla',
    role: 'Director Of Business and Programs Development',
    imgSrc: hammuniImg,
  },
  {
    name: 'Johar Sheik',
    role: 'Director of Social Impact and Community Health',
    imgSrc: joharImg,
  },
  {
    name: 'Mohamed Fuad',
    role: 'Community Development Manager',
    imgSrc: mohamedImg,
  },
  {
    name: 'Abdiqadir Abuukar',
    role: 'Events Strategy and Planning Manager',
    imgSrc: aqImg,
  },
  {
    name: 'Hamse Hassan',
    role: 'Director of Education and Learning Initiatives ',
    imgSrc: hamseImg,
  },
  {
    name: 'Samatar Goth',
    role: 'Marketing and Communications Manager',
    imgSrc: samImg,
  },
  {
    name: 'Ilias cherkaoui',
    role: 'Treasurer',
    imgSrc: iliasImg,
  },
];

const TeamMembersSection = ({containerClassName, title, teamMembers}) => (
  <div
    className={`${containerClassName.toLowerCase()}-team-members team-members`}
  >
    <ul>
      {teamMembers.map((member, index) => (
        <li key={index}>
          <img src={member.imgSrc} alt={member.name} />
          <div className="wrapper">
            <span className="name">{member.name}</span>
            <span className="role">{member.role}</span>
          </div>
        </li>
      ))}
    </ul>
  </div>
);

const OurTeam = () => (
  <>
    <TeamMembersSection
      containerClassName="executives"
      title="Executive Leadership Team"
      teamMembers={executiveTeamMembers}
    />
  </>
);

export default OurTeam;
