import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Button } from '@material-ui/core';

const CookieConsent = () => {
  const [consentGiven, setConsentGiven] = useState(false);

  useEffect(() => {
    // Check if the consent cookie exists
    const hasConsent = Cookies.get('cookieConsent');
    if (hasConsent === 'true') {
      setConsentGiven(true);
    }
  }, []); // Run this effect only once on component mount

  const handleAccept = () => {
    Cookies.set('cookieConsent', 'true', { expires: 365 }); // Set consent cookie to expire in 1 year
    setConsentGiven(true);
  };

  const handleReject = () => {
    // Set a new cookie to remember the user's rejection preference
    Cookies.set('cookieConsent', 'false', { expires: 365 }); // Set consent cookie to expire in 1 year
    setConsentGiven(true); // Hide the consent banner
  };

  if (consentGiven) {
    return null; // If consent is given, hide the consent banner
  }

  return (
    <div className="cookie-consent">
      <p>
        This website uses cookies to ensure you get the best experience.{' '}
        <a href="/cookie-policy">Learn more</a>
      </p>
      <div className="button-wrapper">
        <Button className="reject-btn" onClick={handleReject}>
          Reject
        </Button>
        <Button className="accept-btn" onClick={handleAccept}>
          Accept
        </Button>
      </div>
    </div>
  );
};

export default CookieConsent;
