export const ValuesData = [
    {
      title: "Inclusivity",
      content:
        "We embrace and celebrate diverse backgrounds, perspectives, and experiences, fostering an environment where every individual feels valued and heard.",
    },
    {
      title: "Empowerment",
      content:
        "We empower individuals through education, well-being initiatives, and economic opportunities, providing the tools and support needed for personal and community growth.",
    },
    {
      title: "Integrity",
      content:
        "We uphold the highest standards of honesty, transparency, and ethical conduct in all our endeavors, ensuring trust and credibility in our interactions with our community and stakeholders.",
    },
    {
      title: "Innovation",
      content:
        "We embrace a forward-thinking mindset, constantly seeking creative solutions and adapting to change, as we strive to innovate in our approach to creating positive change.",
    },
    {
      title: "Collaboration",
      content:
        "We promote a culture of teamwork and cooperation, encouraging the exchange of ideas and skills to achieve collective success and positive impact.",
    },
  ];