import React, {useState, useEffect} from 'react';
import '../css/Showcase.css';
import {motion} from 'framer-motion';
import {Link} from 'react-router-dom';
import {Button} from '@material-ui/core';
import {MdOutlineKeyboardDoubleArrowUp} from 'react-icons/md';
import {FaAngleRight} from 'react-icons/fa6';
import {getStorage, ref, listAll, getDownloadURL} from 'firebase/storage';

const ShowCase = () => {
  const [showcaseTopImg, setShowcaseTopImg] = useState('');
  const [showcaseBottomImg, setShowcaseBottomImg] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storage = getStorage();
    const topImgRef = ref(storage, 'images/showcase-top-img.png');
    const bottomImgRef = ref(storage, 'images/showcase-bottom-img.png');
    const bgImgRef = ref(storage, 'images/showcase-bg.jpg');

    const fetchImage = async (imageRef, setImage) => {
      try {
        const url = await getDownloadURL(imageRef);
        setImage(url);
      } catch (error) {
        console.error('Error getting image:', error);
      }
    };

    Promise.all([
      fetchImage(topImgRef, setShowcaseTopImg),
      fetchImage(bottomImgRef, setShowcaseBottomImg),
      fetchImage(bgImgRef, setBackgroundImage),
    ]).then(() => setLoading(false));
  }, []);

  if (loading) {
    return <div className="showcase-loading-screen"></div>;
  }

  return (
    <div
      className="banner"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="banner-container">
        <div className="showcase-top-section">
          <div className="container-img">
            <img src={showcaseTopImg} alt="showcase-container" />
          </div>
          <div className="banner-content">
            <div className="showcase-header">
              <div className="title-header">
                <div className="links-wrapper">
                  <Link to="/services">
                    <motion.h1
                      className="title"
                      initial={{y: -100, opacity: 0}}
                      animate={{y: 0, opacity: 1}}
                      transition={{duration: 0.5, delay: 0.2}}
                    >
                      Explore
                    </motion.h1>
                  </Link>
                  <Link to="/blog">
                    <motion.h1
                      className="title"
                      initial={{y: -100, opacity: 0}}
                      animate={{y: 0, opacity: 1}}
                      transition={{duration: 0.7, delay: 0.7}}
                    >
                      Engage
                    </motion.h1>
                  </Link>
                  <Link to="/services/education" className="title-icon">
                    <MdOutlineKeyboardDoubleArrowUp className="icon" />
                    <motion.h1
                      className="title"
                      initial={{y: -100, opacity: 0}}
                      animate={{y: 0, opacity: 1}}
                      transition={{duration: 0.9, delay: 1.2}}
                    >
                      Elevate
                    </motion.h1>
                  </Link>
                </div>
              </div>
              <div className="content-header">
                <div className="para-wrapper">
                  <p>
                    Discover the transformative power of unity at Unity
                    Horizons. Through our diverse programs and collaborative
                    efforts, we're driving positive change for marginalised
                    communities.{' '}
                    <strong>Join us in creating a brighter future.</strong>
                  </p>
                </div>
                <Link to="/about-us">
                  <Button>Learn more</Button>
                  <FaAngleRight />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-bottom-img">
          <img src={showcaseBottomImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ShowCase;
