import React, {useState} from 'react';
import axios from '../containers/axios';
import '../components/css/Forms.css';
import Error from '../components/common/Error'; // Import Error component
import Success from '../components/common/Success'; // Import Success component
import {Link} from 'react-router-dom';

const areasOfInterestOptions = [
  {
    id: 'collaborative-projects',
    value: 'Collaborative Projects',
    label: 'Collaborative Projects',
  },
  {
    id: 'event-sponsorship',
    value: 'Event Sponsorship',
    label: 'Event Sponsorship',
  },
  {
    id: 'educational-programs',
    value: 'Educational Programs',
    label: 'Educational Programs',
  },
  {id: 'other-interests', value: 'Other', label: 'Other'},
];

const PartnerSignup = () => {
  // Define state variables for form fields, error, and success messages
  const [companyName, setCompanyName] = useState('');
  const [contactName, setContactName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [organisationType, setOrganisationType] = useState('');
  const [areasOfInterest, setAreasOfInterest] = useState([]);
  const [organisationDescription, setOrganisationDescription] = useState('');
  const [partneredBefore, setPartneredBefore] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [agreedTerms, setAgreedTerms] = useState(false);

  const handleCheckboxChange = (event) => {
    const {value, checked} = event.target;
    setAreasOfInterest((prevState) =>
      checked
        ? [...prevState, value]
        : prevState.filter((item) => item !== value)
    );
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Make an HTTP POST request to your backend route
      const response = await axios.post('/submit-partner-form', {
        companyName: companyName,
        contactName: contactName,
        email: email,
        phone: phone,
        organisationType: organisationType,
        areasOfInterest: areasOfInterest,
        organisationDescription: organisationDescription,
        partneredBefore: partneredBefore,
        message: message,
        agreedTerms: agreedTerms,
      });

      setSuccess(response.data.message); // Handle success
      setError(null); // Reset error state
      setCompanyName('');
      setContactName('');
      setEmail('');
      setPhone('');
      setOrganisationType('');
      setAreasOfInterest([]);
      setOrganisationDescription('');
      setPartneredBefore('');
      setMessage('');
      setAgreedTerms(false);
    } catch (error) {
      setError(error.response.data.message); // Set error message
      setSuccess(null); // Reset success state
    }
  };

  return (
    <div className="form-page partner-page">
      <div className="intro-section">
        <h1>Partner With Us</h1>
        <p>
          Interested in partnering with us? Please fill out the form below and
          we'll get back to you as soon as possible.
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-content-wrapper">
          <label>
            Company Name:
            <input
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
              placeholder="Company Name"
            />
          </label>
          <label>
            Contact Name:
            <input
              type="text"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              required
              placeholder="Contact Name"
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Email"
            />
          </label>
          <label>
            Phone:
            <input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              placeholder="Digits only, no special characters"
            />
          </label>
          <label>
            Organisation Type:
            <select
              value={organisationType}
              onChange={(e) => setOrganisationType(e.target.value)}
              required
            >
              <option value="">Select Organisation Type</option>
              <option value="Non-Profit">Non-Profit</option>
              <option value="Corporate">Corporate</option>
              <option value="Educational Institution">
                Educational Institution
              </option>
              <option value="Other">Other</option>
            </select>
          </label>
          <label>
            Areas of Interest:
            <div className="checkbox-container">
              {areasOfInterestOptions.map((option) => (
                <label className="checkbox-label" key={option.id}>
                  {option.label}
                  <input
                    type="checkbox"
                    id={option.id}
                    value={option.value}
                    checked={areasOfInterest.includes(option.value)}
                    onChange={handleCheckboxChange}
                  />
                </label>
              ))}
            </div>
          </label>

          <label>
            Briefly describe your organisation and how you wish to partner with
            us:
            <textarea
              value={organisationDescription}
              onChange={(e) => setOrganisationDescription(e.target.value)}
              rows="4"
              required
              placeholder="Organisation Description"
            ></textarea>
          </label>
          <label>
            Have you partnered with a nonprofit before?
            <div className="radio-container">
              <label className="radio-label" htmlFor="yes">
                Yes
                <input
                  type="radio"
                  id="yes"
                  name="partneredBefore"
                  value="Yes"
                  checked={partneredBefore === 'Yes'}
                  onChange={(e) => setPartneredBefore(e.target.value)}
                />
              </label>
              <label className="radio-label" htmlFor="no">
                No
                <input
                  type="radio"
                  id="no"
                  name="partneredBefore"
                  value="No"
                  checked={partneredBefore === 'No'}
                  onChange={(e) => setPartneredBefore(e.target.value)}
                />
              </label>
            </div>
          </label>
        </div>
        <label className="lrg-textarea">
          Message:
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="4"
            required
            placeholder="Message"
          ></textarea>
        </label>
        <label className="terms-checkbox-label">
          <p>
            I agree to the{' '}
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
          </p>
          <input
            type="checkbox"
            checked={agreedTerms}
            onChange={() => setAgreedTerms(!agreedTerms)}
            required
          />
        </label>
        {error && <Error message={error} clearError={() => setError('')} />}{' '}
        {/* Display error message if error state is set */}
        {success && (
          <Success message={success} clearSuccess={() => setSuccess('')} />
        )}{' '}
        {/* Display success message if success state is set */}
        <button className="submit-button" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default PartnerSignup;
