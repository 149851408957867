import React from 'react';
import '../components/css/Faqs.css';

const Faqs = () => {
  const faqs = [
    {
      question: 'What is a CIC (Community Interest Company)?',
      answer:
        'A CIC is a type of company designed for social enterprises that want to use their profits and assets for the public good.',
    },
    {
      question: 'How is a CIC different from other types of companies?',
      answer:
        'Unlike traditional companies, CICs have a legal obligation to reinvest their profits into the community rather than distributing them to shareholders.',
    },
    {
      question: 'How are CICs regulated?',
      answer:
        'CICs are regulated by the Office of the Regulator of Community Interest Companies (ORCIC), ensuring compliance with the CIC Regulations and community interest provisions.',
    },
    {
      question: 'How do CICs benefit the community?',
      answer:
        'CICs benefit the community by addressing social or environmental issues, creating jobs, supporting local economies, and delivering essential services.',
    },
    {
      question: 'What types of activities can a CIC undertake?',
      answer:
        'CICs can undertake a wide range of activities, including education, healthcare, environmental conservation, arts and culture, social housing, and community development.',
    },
    {
      question: 'How can I support or get involved with a CIC?',
      answer:
        'You can support a CIC by volunteering your time, donating funds or resources, purchasing products or services from them, or spreading awareness about their cause.',
    },
    {
      question: 'What services does your company provide?',
      answer:
        'We provide a range of services including consultation, product development, project management, and technical support. Our services are tailored to meet the diverse needs of our clients and partners.',
    },
    {
      question: 'How can I contact customer support?',
      answer:
        'You can contact our customer support team via email at support@example.com or by phone at +123456789. Our support representatives are available during business hours to assist you with any inquiries or issues you may have.',
    },
    {
      question: 'Is my personal information safe with your company?',
      answer:
        'Yes, we prioritize the security and privacy of your information. We implement industry-standard security measures to protect your personal data from unauthorized access, use, or disclosure. Additionally, we comply with data protection regulations to ensure the confidentiality and integrity of your information.',
    },
    {
      question: 'How often do you update your services?',
      answer:
        'We strive to provide regular updates to improve our services and enhance the user experience. Our development team continuously evaluates and implements new features, optimizations, and security enhancements to ensure that our services meet the evolving needs of our clients and adhere to industry standards.',
    },
  ];

  return (
    <div className="faq-container">
      <h1>Frequently Asked Questions</h1>
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <h2>{faq.question}</h2>
          <p>{faq.answer}</p>
        </div>
      ))}
    </div>
  );
};

export default Faqs;
