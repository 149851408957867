import React, {useRef} from 'react';
import '../css/HomePageAbout.css';
import {Link} from 'react-router-dom';
import {Button} from '@material-ui/core';
import {ArrowRightAlt} from '@material-ui/icons';

const HomePageAbout = () => {
  const aboutSectionRef = useRef(null);

  return (
    <div id="home-page-about" className="home-page-about" ref={aboutSectionRef}>
      <div className="container">
        <div className="header-wrapper">
          <div className="mission-statement">
            <div className="mission-statement-wrapper">
              <h2 className="mission-title">
                TRANSFORMING VISIONS INTO REALITY
              </h2>
              <p className="light-bg">
                At Unity Horizons, we're passionately committed to empowering
                marginalised communities. Our mission is rooted in action:
                Championing education, elevating voices, and opening doors of
                opportunity for all.
              </p>
              <p className="light-bg">
                In our comprehensive range of programs and services,
                encompassing
                <Link to="/services/education"> education</Link>,{' '}
                <Link to="/services/sports">well-being</Link> and{' '}
                <Link to="/services/community-events">
                  community engagement
                </Link>
                <span>
                  {' '}
                  you'll confidently boost both your personal and professional
                  strengths, develop resilience, and uncover new opportunities.
                </span>
              </p>
              <p>
                Our welcoming community offers extensive networking
                opportunities, access to valuable resources, and unwavering
                support tailored just for you. Together, we champion inclusivity
                and positive change, empowering you and each other to succeed.
                Let's create a future where you can thrive in all aspects of
                your life with confidence.
              </p>
            </div>
          </div>
          <div className="showcase-call-to-action-btn">
            <div className="btn-link-container">
              <Link className="member-btn-link" to="/membership-signup">
                <Button>
                  <span>Become a Member</span>
                  <ArrowRightAlt />
                </Button>
              </Link>
              <Link className="partner-btn-link" to="/partner-signup">
                <Button>
                  <span>Partner With Us</span>
                  <ArrowRightAlt />
                </Button>
              </Link>
              <Link className="volunteer-btn-link" to="/volunteer-signup">
                <Button>
                  <span>Volunteer</span>
                  <ArrowRightAlt />
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="become-member-action-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 900 177"
              preserveAspectRatio="none"
              className="svg-before"
            >
              <path d="M0 177L75 167.3C150 157.7 300 138.3 450 136.3C600 134.3 750 149.7 825 157.3L900 165L900 0L825 0C750 0 600 0 450 0C300 0 150 0 75 0L0 0Z" />
            </svg>
            <div className="btn-wrapper">
              <p className="hero-content">
                Ready to take control of your future? Join our vibrant community
                now and unlock the tools, resources, and support you need to
                transform your life.
                <strong>
                  {' '}
                  Plus, claim your exclusive welcome gift when you join us
                  today!
                </strong>
              </p>
              <h1>Join Our Empowering Community Today</h1>
              <Button>
                <Link to="/membership-signup">Transform Your Life Now</Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageAbout;
