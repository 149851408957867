import React from 'react';
import '../css/Testimonials.css';

const testimonialsData = [
  {
    id: 1,
    content: `"Well, as a mum and a nurse; It would be dishonest to neglect the fact that our children find it tough to get the care and attention they need. Particularly during their studies. So, having educational programs that give them a hand and a leg up? That's just one of the initiatives we must have to help them succeed"`,
    author: 'Ayaan Ahmed ',
    role: 'Mum and Nurse',
  },
  {
    id: 2,
    content: `"Teaching the kids in our community has been a real eye opener. They've got so much potential, but sometimes they just need a little push in the right direction. Some are already on the wrong path, and others may begin to start their journey soon.  Therefore, the work you all do, is absolutely necessary. Especially now more than ever. I commend your dedication to positive change through your services."`,
    author: 'Ashleigh Mills ',
    role: 'Teacher',
  },
  {
    id: 3,
    content: `"Working with the young lads on the footy field has been a blast. It's more than just a game – it's about building confidence, teamwork, and giving them a sense of belonging. Football is often unnoticed as an effective tool of prevention and intervention; Socially speaking. So it’s refreshing to see Unity Horizons strategy include sports in its mission."`,
    author: 'Craigey',
    role: 'Football Coach and Agent.',
  },
  {
    id: 4,
    content: `"I think Luton needs to focus on giving our young people better education options, backing our local businesses, and making our streets safer for everyone. It's about building a community we can all be proud of."`,
    author: 'Guled Mohamed',
    role: 'Youth Mentor',
  },
  {
    id: 5,
    content: `"Mental health is at the core of people’s well-being. We need easier access to support, greater awareness, and a community that stands together in times of struggle. We have to make mental health a top priority for a stronger, more resilient Luton."`,
    author: 'Aliya',
    role: 'Student',
  },
  {
    id: 6,
    content: `"What our community really needs is to come together as one. We need to support each other, celebrate our differences, and stand strong, side by side. When we're united, there's nothing we can't achieve."`,
    author: 'Fehzan Malik',
    role: 'Business Owner',
  },
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonialsData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonialsData.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="testimonials">
      <div className="container">
        <div className="slider">
          <div className="testimonial-card">
            <div className="testimonial-content">
              <p>{testimonialsData[currentIndex].content}</p>
            </div>
            <div className="testimonial-author">
              <h3>{testimonialsData[currentIndex].author}</h3>
              <p>{testimonialsData[currentIndex].role}</p>
            </div>
          </div>
          <button onClick={prevSlide} className="prev-btn">
            &#10094;
          </button>
          <button onClick={nextSlide} className="next-btn">
            &#10095;
          </button>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
