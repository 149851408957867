import React from 'react';
import banner from '../videos/showcase-slider-vid.mp4';
import '../css/VideoComponent.css';
const ServicesVideoBanner = () => {
  return (
    <video autoPlay loop muted playsInline style={{pointerEvents: 'none'}}>
      <source src={banner} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

const ReusableVideoComponent = ({src}) => {
  return (
    <video autoPlay loop muted playsInline style={{pointerEvents: 'none'}}>
      <source src={src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export {ServicesVideoBanner, ReusableVideoComponent};
