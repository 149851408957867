import React, {useState} from 'react';
import {motion} from 'framer-motion';

import {KeyboardArrowDown, KeyboardArrowUp} from '@material-ui/icons';
import {
  MdGroupWork,
  MdHandshake,
  MdLocalLibrary,
  MdPeople,
  MdSchool,
  MdSports,
  MdSupport,
} from 'react-icons/md';

import {Link} from 'react-router-dom';
import '../css/LargeScreenNav.css';

const LargeScreenNav = () => {
  const [showServices, setShowServices] = useState(false);
  const [openServicesSubMenu, SetOpenServicesSubMenu] = useState(false);
  const [showForms, setShowForms] = useState(false);
  const [openFormsSubMenu, SetOpenFormsSubMenu] = useState(false);

  const handleServicesClick = () => {
    setShowServices(!showServices);
  };
  const handleFormsClick = () => {
    setShowForms(!showForms);
  };
  const toggleServicesSubOpenMenu = () => {
    SetOpenServicesSubMenu(!openServicesSubMenu); // Toggles the state value
    handleServicesClick();
  };
  const toggleFormsSubOpenMenu = () => {
    SetOpenFormsSubMenu(!openFormsSubMenu); // Toggles the state value
    handleFormsClick();
  };

  return (
    <motion.div className="large-screen-menu">
      {showServices && (
        <div className="overlay" onClick={toggleServicesSubOpenMenu}></div>
      )}
      {showForms && (
        <div className="overlay" onClick={toggleFormsSubOpenMenu}></div>
      )}

      <div className="menu-container">
        <ul className="main-menu-list">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about-us">About us</Link>
          </li>
          <li className="services-li">
            <div
              className={`services-wrapper ${
                showServices && 'underline__services__wrapper'
              }`}
            >
              <Link to="/services">Services</Link>
              {openServicesSubMenu ? (
                <KeyboardArrowUp
                  onClick={toggleServicesSubOpenMenu}
                  className={`icon ${openServicesSubMenu && 'active__icon'}`}
                />
              ) : (
                <KeyboardArrowDown
                  onClick={toggleServicesSubOpenMenu}
                  className={`icon ${openServicesSubMenu && 'active__icon'}`}
                />
              )}
            </div>

            {showServices && (
              <ul
                className={`sub__menu ${
                  openServicesSubMenu && 'show__sub__menu'
                }`}
              >
                <li>
                  <Link
                    onClick={toggleServicesSubOpenMenu}
                    to="/services/education"
                  >
                    <MdSchool className="icon" />
                    <span>Education</span>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={toggleServicesSubOpenMenu}
                    to="/services/sports"
                  >
                    <MdSports className="icon" />
                    <span>Health and Wellbeing</span>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={toggleServicesSubOpenMenu}
                    to="/services/workshops"
                  >
                    <MdLocalLibrary className="icon" />
                    <span>Workshops</span>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={toggleServicesSubOpenMenu}
                    to="/services/community-events"
                  >
                    <MdPeople className="icon" />
                    <span>Community Events</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="services-li">
            <div
              className={`services-wrapper ${
                showForms && 'underline__services__wrapper'
              }`}
            >
              <Link onClick={toggleFormsSubOpenMenu}>Get Involved</Link>
              {openFormsSubMenu ? (
                <KeyboardArrowUp
                  onClick={toggleFormsSubOpenMenu}
                  className={`icon ${openFormsSubMenu && 'active__icon'}`}
                />
              ) : (
                <KeyboardArrowDown
                  onClick={toggleFormsSubOpenMenu}
                  className={`icon ${openFormsSubMenu && 'active__icon'}`}
                />
              )}
            </div>

            {showForms && (
              <ul
                className={`sub__menu ${openFormsSubMenu && 'show__sub__menu'}`}
              >
                <li>
                  <Link
                    onClick={toggleFormsSubOpenMenu}
                    to="/membership-signup"
                  >
                    <MdGroupWork className="icon" />
                    <span>Become a Member</span>
                  </Link>
                </li>
                <li>
                  <Link onClick={toggleFormsSubOpenMenu} to="/partner-signup">
                    <MdHandshake className="icon" />
                    <span>Partner With Us</span>
                  </Link>
                </li>
                <li>
                  <Link onClick={toggleFormsSubOpenMenu} to="/volunteer-signup">
                    <MdSupport className="icon" />
                    <span>Volunteer</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <Link to="/contact-us">Contact Us</Link>
          </li>
        </ul>
      </div>
    </motion.div>
  );
};

export default LargeScreenNav;
