import React, {useState} from 'react';
import axios from '../containers/axios';
import '../components/css/Forms.css';
import Error from '../components/common/Error'; // Import Error component
import Success from '../components/common/Success'; // Import Success component
import countries from '../components/forms/countries';
import {Link} from 'react-router-dom';

const areasOfInterestOptions = [
  {
    id: 'educational-programs',
    value: 'Educational Programs',
    label: 'Educational Programs',
  },
  {
    id: 'fitness-activities',
    value: 'Fitness Activities',
    label: 'Fitness Activities',
  },
  {
    id: 'skill-building-workshops',
    value: 'Skill-Building Workshops',
    label: 'Skill-Building Workshops',
  },
  {
    id: 'community-events',
    value: 'Community Events',
    label: 'Community Events',
  },
];

const MembershipSignup = () => {
  // Define state variables for form fields, error, and success messages
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [age, setAge] = useState('');
  const [ethnicity, setEthnicity] = useState('');
  const [areasOfInterest, setAreasOfInterest] = useState([]);
  const [reasonForJoining, setReasonForJoining] = useState('');
  const [gender, setGender] = useState('');
  const [error, setError] = useState(null);
  const [PostcodeError, setPostcodeError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [postcode, setPostcode] = useState('');
  const [areaInfo, setAreaInfo] = useState(null);
  const [agreedTerms, setAgreedTerms] = useState(false);

  // Postcode look up function
  const handPostcodeLookup = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    // Check if the postcode input field is empty or contains only whitespace
    if (!postcode.trim()) {
      setPostcodeError('Please enter a postcode');
      return;
    }

    try {
      // Make the API request if the postcode is not empty
      const response = await axios.get(
        `https://api.postcodes.io/postcodes/${postcode}`
      );
      setAreaInfo(response.data.result);
      setPostcodeError(null);
    } catch (error) {
      setPostcodeError(
        'Invalid postcode. Please make sure you have entered a valid UK postcode.'
      );
      setAreaInfo(null);
    }
  };

  // Areas of interest function
  const handleCheckboxChange = (event) => {
    const {value, checked} = event.target;
    setAreasOfInterest((prevState) =>
      checked
        ? [...prevState, value]
        : prevState.filter((item) => item !== value)
    );
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Make an HTTP POST request to your backend route
      const response = await axios.post('/submit-member-form', {
        fullName: fullName,
        email: email,
        phoneNumber: phoneNumber,
        age: age,
        gender: gender,
        postcode: postcode,
        ethnicity: ethnicity,
        areasOfInterest: areasOfInterest,
        reasonForJoining: reasonForJoining,
        agreedTerms: agreedTerms,
      });

      setSuccess(response.data.message); // Handle success
      setError(null); // Reset error state
      setFullName('');
      setEmail('');
      setPhoneNumber('');
      setAge('');
      setPostcode('');
      setEthnicity('');
      setAreasOfInterest([]);
      setReasonForJoining('');
      setGender('');
      setAgreedTerms(false);
    } catch (error) {
      window.scrollTo({top: 0, behavior: 'smooth'});

      setError(error.response.data.message); // Set error message
      setSuccess(null); // Reset success state
    }
  };

  return (
    <div className="form-page member-signUp">
      <div className="intro-section">
        <h1>Become a Member</h1>
        <p>
          Join our community today and become a member to access exclusive
          benefits, events, and resources. Fill out the form below to get
          started!
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-content-wrapper">
          <label>
            Full Name:
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
              placeholder="Name"
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Email"
            />
          </label>
          <label>
            Phone Number:
            <input
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              placeholder="Digits only, no special characters"
            />
          </label>
          <label>
            Age:
            <input
              type="number"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              required
              placeholder="Age"
            />
          </label>
          <label>
            Gender:
            <select
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              required
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </label>
          <label htmlFor="postcode" className="postcode-lookup">
            Postcode:
            <div className="container">
              <input
                type="text"
                id="postcode"
                value={postcode}
                onChange={(e) => setPostcode(e.target.value)}
                placeholder="Enter postcode"
              />
              <button className="lookup-btn" onClick={handPostcodeLookup}>
                Lookup
              </button>
            </div>
            {PostcodeError && <p className="postcode-error">{PostcodeError}</p>}
            {areaInfo && (
              <div className="content-data">
                <h2>Area Information</h2>
                <p>
                  <strong>Postcode:</strong>{' '}
                  {areaInfo.postcode ? `${areaInfo.postcode}` : 'N/A'}
                </p>
                <p>
                  <strong>Area:</strong>{' '}
                  {areaInfo.admin_ward ? `${areaInfo.admin_ward}` : 'N/A'}
                </p>
                <p>
                  {' '}
                  <strong>City/Town:</strong>{' '}
                  {areaInfo.nuts ? `${areaInfo.nuts}` : 'N/A'}
                </p>
                <p>
                  {' '}
                  <strong>County:</strong>{' '}
                  {areaInfo.pfa ? `${areaInfo.pfa}` : 'N/A'}
                </p>
              </div>
            )}
          </label>
          <label>
            Ethnicity:
            <select
              value={ethnicity}
              onChange={(e) => setEthnicity(e.target.value)}
              required
            >
              <option value="">Select Ethnicity</option>
              {countries.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </label>
          <label>
            Areas of Interest:
            <div className="checkbox-container">
              {areasOfInterestOptions.map((option) => (
                <label className="checkbox-label" key={option.id}>
                  {option.label}
                  <input
                    type="checkbox"
                    id={option.id}
                    value={option.value}
                    checked={areasOfInterest.includes(option.value)}
                    onChange={handleCheckboxChange}
                  />
                </label>
              ))}
            </div>
          </label>
        </div>
        <label className="lrg-textarea">
          Reason for Joining:
          <textarea
            value={reasonForJoining}
            onChange={(e) => setReasonForJoining(e.target.value)}
            rows="4"
            placeholder="Reason for Joining"
          ></textarea>
        </label>
        <label className="terms-checkbox-label">
          <p>
            I agree to the{' '}
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
          </p>
          <input
            type="checkbox"
            checked={agreedTerms}
            onChange={() => setAgreedTerms(!agreedTerms)}
            required
          />
        </label>
        {error && <Error message={error} clearError={() => setError('')} />}{' '}
        {/* Display error message if error state is set */}
        {success && (
          <Success message={success} clearSuccess={() => setSuccess('')} />
        )}{' '}
        {/* Display success message if success state is set */}
        <button className="submit-button" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default MembershipSignup;
